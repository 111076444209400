@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;400&display=swap");
$background: #f6f8fc;
$blueColor: rgba(189, 220, 251, 0.5);
$primaryMain: #00ad61;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    font-family: "Sora", sans-serif;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    background-color: $background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    width: 100%;
    height: 100%;
    background-color: $background;
}

a {
    color: inherit;
    text-decoration: none;
}

.bold-border {
    border: 3px solid #000000;
    border-radius: 5px;
}

.bgcolor-blue {
    background-color: $blueColor;
}

.text-gray {
    color: #828282;
}

.text-green {
    color: rgba(33, 150, 83);
}

.fill-in-gap-box {
    height: 20px;
    min-width: 30px;
    border: 1px #2f80ed solid;
    border-radius: 3px;
    display: inline-flex;
    margin-left: 5px;
    margin-right: 5px;
    align-self: center;
    background-color: ye;
}

.text-xp {
    color: #cf6d19;
}

// Dot Revolution && loading
.dot-revolution {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primaryMain;
    color: $primaryMain;
}

.dot-revolution::before,
.dot-revolution::after {
    content: "";
    display: inline-block;
    position: absolute;
}

.dot-revolution::before {
    left: 0;
    top: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primaryMain;
    color: $primaryMain;
    transform-origin: 5px 20px;
    animation: dot-revolution 1.4s linear infinite;
}

.dot-revolution::after {
    left: 0;
    top: -30px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primaryMain;
    color: $primaryMain;
    transform-origin: 5px 35px;
    animation: dot-revolution 1s linear infinite;
}

@keyframes dot-revolution {
    0% {
        transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
        transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
}
